var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-register","size":"lg","centered":"","title":"Thêm người lao động đăng kí tìm việc làm","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu"},on:{"ok":_vm.handleOk,"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Họ và tên "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Họ và tên","rules":"required","custom-messages":_vm.customName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.detailUser.name),callback:function ($$v) {_vm.$set(_vm.detailUser, "name", $$v)},expression:"detailUser.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày sinh ")]),_c('date-picker',{attrs:{"disabledInput":true},model:{value:(_vm.detailUser.birthDay),callback:function ($$v) {_vm.$set(_vm.detailUser, "birthDay", $$v)},expression:"detailUser.birthDay"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('small',[_vm._v("Giới tính")]),_c('b-form-radio-group',{staticClass:"mt-2",attrs:{"id":"radio-group-1","options":_vm.options,"name":"radio-options"},model:{value:(_vm.detailUser.gender),callback:function ($$v) {_vm.$set(_vm.detailUser, "gender", $$v)},expression:"detailUser.gender"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số CMND/CCCD/HC ")]),_c('b-form-input',{model:{value:(_vm.detailUser.identityCard),callback:function ($$v) {_vm.$set(_vm.detailUser, "identityCard", $$v)},expression:"detailUser.identityCard"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Trình độ học vấn "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Trình độ học vấn","rules":"required","custom-messages":_vm.customLevel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid':errors.length > 0 },attrs:{"reduce":function (value) { return value.value; },"label":"label","options":_vm.optionsSelect || [],"placeholder":"Trình độ học vấn"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.detailUser.academicLevel),callback:function ($$v) {_vm.$set(_vm.detailUser, "academicLevel", $$v)},expression:"detailUser.academicLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Địa chỉ")]),_c('b-form-input',{model:{value:(_vm.detailUser.address),callback:function ($$v) {_vm.$set(_vm.detailUser, "address", $$v)},expression:"detailUser.address"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('b-form-input',{model:{value:(_vm.detailUser.phoneNumber),callback:function ($$v) {_vm.$set(_vm.detailUser, "phoneNumber", $$v)},expression:"detailUser.phoneNumber"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nghề nghiệp")]),_c('occupation-select',{model:{value:(_vm.detailUser.typeOfProfessionId),callback:function ($$v) {_vm.$set(_vm.detailUser, "typeOfProfessionId", $$v)},expression:"detailUser.typeOfProfessionId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Công việc muốn tìm")]),_c('b-form-input',{model:{value:(_vm.detailUser.job),callback:function ($$v) {_vm.$set(_vm.detailUser, "job", $$v)},expression:"detailUser.job"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Ngày đăng kí làm việc "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"Ngày đăng kí làm việc","rules":"required","custom-messages":_vm.customDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"disabledInput":true},model:{value:(_vm.detailUser.dateRegister),callback:function ($$v) {_vm.$set(_vm.detailUser, "dateRegister", $$v)},expression:"detailUser.dateRegister"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }