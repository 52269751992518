<template>
  <b-modal
    id="modal-add-register"
    size="lg"
    centered
    title="Thêm người lao động đăng kí tìm việc làm"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleOk"
    @hidden="resetData"
  >

    <validation-observer ref="rule">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Họ và tên <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Họ và tên"
              rules="required"
              :custom-messages="customName"
            >
              <b-form-input
                v-model="detailUser.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày sinh </label>
            <date-picker
              v-model="detailUser.birthDay"
              :disabledInput="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <small>Giới tính</small>
          <b-form-radio-group
            id="radio-group-1"
            v-model="detailUser.gender"
            class="mt-2"
            :options="options"
            name="radio-options"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số CMND/CCCD/HC </label>
            <b-form-input v-model="detailUser.identityCard" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Trình độ học vấn <span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="Trình độ học vấn"
              rules="required"
              :custom-messages="customLevel"
            >
              <v-select
                v-model="detailUser.academicLevel"
                :reduce="(value) => value.value"
                :class="{'is-invalid':errors.length > 0 }"
                label="label"
                :options="optionsSelect || []"
                placeholder="Trình độ học vấn"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Địa chỉ</label>
            <b-form-input
              v-model="detailUser.address"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input
              v-model="detailUser.phoneNumber"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Nghề nghiệp</label>
            <occupation-select
              v-model="detailUser.typeOfProfessionId"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Công việc muốn tìm</label>
            <b-form-input
              v-model="detailUser.job"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label>Ngày đăng kí làm việc <span class="text-danger">(*)</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="Ngày đăng kí làm việc"
              rules="required"
              :custom-messages="customDate"
            >
              <date-picker
                v-model="detailUser.dateRegister"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import OccupationSelect from '@/views/management-workers/occupation-list/pages/components/OccupationSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    vSelect,
    BFormInput,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    OccupationSelect,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          name: null,
          birthDay: null,
          gender: true,
          identityCard: null,
          academicLevel: null,
          address: null,
          phoneNumber: null,
          typeOfProfessionId: null,
          job: null,
          dateRegister: null,
        }
      },
    },
  },
  data() {
    return {
      detailUser: JSON.parse(JSON.stringify(this.data)),
      customName: {
        required: 'Họ và tên là bắt buộc',
      },
      customDate: {
        required: 'Ngày đăng kí làm việc là bắt buộc',
      },
      customLevel: {
        required: 'Trình độ học vấn là bắt buộc',
      },
      options: [
        { text: 'Nam', value: true },
        { text: 'Nữ', value: false },
      ],
      optionsSelect: [
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.detailUser = JSON.parse(JSON.stringify(val))
      },
    },
  },
  methods: {
    resetData() {
      this.detailUser = JSON.parse(JSON.stringify(this.data))
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          this.$bvModal.hide('modal-add-register')
          this.$emit('update', this.detailUser)
        }
      })
    },
  },
}
</script>
