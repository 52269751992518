import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListUser(payload) {
    try {
      const { data } = await axiosApiInstance.get('/RegisterJobWorker/registerJobWorkers', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addUser(payload) {
    try {
      const { data } = await axiosApiInstance.post('/RegisterJobWorker/registerJobWorkers', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async editUser(payload) {
    try {
      const { data } = await axiosApiInstance.put('/RegisterJobWorker/registerJobWorkers', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailUser(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/RegisterJobWorker/registerJobWorkers/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async deleteUser(payload) {
    try {
      const { data } = await axiosApiInstance.post('/RegisterJobWorker/delete-register-job-workers', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
